import { JobStatus } from '@epcbuilder/lib/models/jobs';
import { EpcRating } from '@epcbuilder/lib/models/properties';

export type Filters = {
  currentEpcFilters: EpcRating[];
  potentialEpcFilters: EpcRating[];
  jobStatusFilters: JobStatus[];
  limit: number;
  page: number;
  search: string;
};

export enum FormStage {
  POSTCODE = 0,
  ADDRESS = 1,
}
