import React from 'react';
import { BookingSlot, BookingSlotTimeframe } from '@epcbuilder/lib/models/jobs';
import { isSameDay } from 'date-fns';
import { BookingAvailabilitySlot } from '@/models/job';

const BookingSlotCalendar = ({
  date,
  availableSlots,
  onSlotSelect,
}: {
  date: string;
  availableSlots: BookingAvailabilitySlot[] | undefined;
  onSlotSelect: (date: Date, slot: BookingSlot) => void;
}) => {
  const findAvailableSlot = () => {
    const amSlot = availableSlots?.find((slot) => isSameDay(new Date(slot.date), new Date(date)) && slot.slot === 1);
    const pmSlot = availableSlots?.find((slot) => isSameDay(new Date(slot.date), new Date(date)) && slot.slot === 2);

    return {
      date: date,
      am: amSlot ? amSlot.available : false,
      pm: pmSlot ? pmSlot.available : false,
    };
  };

  const slot = findAvailableSlot();

  const handleSlotClick = (selectedSlot: BookingSlot) => {
    const slotDate = new Date(date);
    onSlotSelect(slotDate, selectedSlot);
  };

  const renderSlots = (daySchedule: { date: string; am: boolean; pm: boolean }) => {
    return (
      <>
        {daySchedule.am ? (
          <button
            type="button"
            onClick={() => handleSlotClick(1)}
            className="bg-blue-dark dark:bg-primary-darker hover:dark:bg-primary mt-2 flex h-[120px] w-[100px] cursor-pointer flex-col items-center justify-center rounded-[12px] hover:bg-[#7AC2B9]"
          >
            <p className="font-bold leading-tight">AM</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.AmSlotStartHour.substring(0, 5)}</p>
            <p className="font-bold leading-tight">-</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.AmSlotEndHour.substring(0, 5)}</p>
          </button>
        ) : (
          <div className="bg-blue-lighter mt-2 flex h-[120px] w-[100px] flex-col items-center justify-center rounded-[12px] dark:bg-[#195E55]">
            <p className="font-bold leading-tight">not</p>
            <p className="font-bold leading-tight">avail.</p>
          </div>
        )}
        {daySchedule.pm ? (
          <button
            type="button"
            onClick={() => handleSlotClick(2)}
            className="bg-blue-dark dark:bg-primary-darker hover:dark:bg-primary mt-2 flex h-[120px] w-[100px] cursor-pointer flex-col items-center justify-center rounded-[12px] hover:bg-[#7AC2B9]"
          >
            <p className="font-bold leading-tight">PM</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.PmSlotStartHour.substring(0, 5)}</p>
            <p className="font-bold leading-tight">-</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.PmSlotEndHour.substring(0, 5)}</p>
          </button>
        ) : (
          <div className="bg-blue-lighter mt-2 flex h-[120px] w-[100px] flex-col items-center justify-center rounded-[12px] dark:bg-[#195E55]">
            <p className="font-bold leading-tight">not</p>
            <p className="font-bold leading-tight">avail.</p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">{slot && renderSlots(slot)}</div>
      <div className="border-blue-dark dark:border-primary-dark h-full border-r-[2px]" />
    </>
  );
};

export default BookingSlotCalendar;
