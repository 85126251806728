import { CreateAddress, PropertyAccessDetails } from '@epcbuilder/lib/models/properties';
import { api } from '@epcbuilder/lib/utils';

export const getProperties = async ({
  currentEpcFilters,
  potentialEpcFilters,
  jobStatusFilters,
  search,
}: {
  currentEpcFilters: number[];
  potentialEpcFilters: number[];
  jobStatusFilters: number[];
  search: string;
}) => {
  let requestUrl = '/properties/filter?';

  if (currentEpcFilters.length > 0) {
    for (const value of currentEpcFilters) {
      requestUrl = `${requestUrl}&currentepcfilters=${value}`;
    }
  }

  if (potentialEpcFilters.length > 0) {
    for (const value of potentialEpcFilters) {
      requestUrl = `${requestUrl}&potentialepcfilters=${value}`;
    }
  }

  if (jobStatusFilters.length > 0) {
    for (const value of jobStatusFilters) {
      requestUrl = `${requestUrl}&jobstatusfilters=${value}`;
    }
  }

  if (search.length > 0) {
    requestUrl = `${requestUrl}&searchTerm=${search}`;
  }

  return await api.get(requestUrl);
};

export const getProperty = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}`);
};

export const getPropertyRatings = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}/rating`);
};

export const getPropertyCount = async () => {
  return await api.get('/properties/user/property-count');
};

export const postProperties = async (data: CreateAddress) => {
  return await api.post('/properties', data);
};

export const deleteProperty = async ({ id }: { id: string }) => {
  return await api.delete(`/properties/${id}`);
};

export const getPropertyAddressCheck = async ({
  postcode,
  addressLine1,
}: {
  postcode: string;
  addressLine1: string;
}) => {
  return await api.get(`/properties/address-check?postcode=${postcode}&addressLine1=${addressLine1}`);
};

export const getPropertyActiveJob = async ({ id }: { id?: string }) => {
  return await api.get(`/properties/${id}/active-job`);
};

export const getPropertyCurrentJob = async ({ id }: { id?: string }) => {
  return await api.get(`/properties/${id}/current-job`);
};

export const putPropertyAccessDetails = async ({ id, data }: { id?: string; data: PropertyAccessDetails }) => {
  return await api.put(`/properties/${id}/access`, data);
};

export const postPropertiesBulkUploader = async ({ ownerStatusId, file }: { ownerStatusId: number; file: File }) => {
  const formData = new FormData();
  formData.set('file', file);
  return await api.post(`/properties/upload?ownerStatus=${ownerStatusId}`, formData);
};

export const getPropertyJobHistory = async () => {
  return await api.get(`/account/job-history`);
};

export const getPropertyCouncilTaxBand = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}/council-tax-band`);
};
