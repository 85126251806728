export const formatSortCode = (input: string): string => {
  const numbersOnly = input.replace(/\D/g, '');
  const parts = [];
  for (let i = 0; i < numbersOnly.length; i += 2) {
    parts.push(numbersOnly.slice(i, i + 2));
  }
  return parts.join('-').slice(0, 8); // Format is "00-00-00"
};

export const formatAccountNumber = (input: string): string => {
  const numbersOnly = input.replace(/\D/g, '');
  return numbersOnly.slice(0, 8); // Limit to 8 digits
};

export const formatPostCode = (input: string): string => {
  return input.slice(0, 8);
};

export const formatForId = (input: string): string => {
  return input.replace(/\s+/g, '-').toLowerCase();
};
