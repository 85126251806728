import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, SensitiveTextInput, TextInput } from '@epcbuilder/lib/components';
import useReferrerSourceCookie from '@epcbuilder/lib/hooks/useReferrerSourceCookie';
import { Register } from '@epcbuilder/lib/models/auth';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postRegister } from '@/network/auth';

const registerSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  password: yup.string().required('Password must not be empty').min(6, 'Password must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
});

const RegisterForm = () => {
  const navigate = useNavigate();
  const refSrcValue = useReferrerSourceCookie();

  const defaultValues: Register = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referrerSource: refSrcValue,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Register>({
    defaultValues,
    resolver: yupResolver(registerSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Register> = async (data) => {
    try {
      await postRegister({
        email: data.email.trim(),
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone.trim(),
        password: data.password,
        confirmPassword: data.confirmPassword,
        referrerSource: data.referrerSource,
      });
      toast.success('Registration successful. Please check your emails to confirm your email address', {
        toastId: 'register-success',
      });
      navigate('/');
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<Register>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        id="email"
        name="email"
        title="Your email address"
        placeholder="Email"
        error={errors.email?.message}
      />
      <TextInput
        {...register('firstName')}
        id="firstName"
        name="firstName"
        title="Your first name"
        placeholder="First name"
        error={errors.firstName?.message}
      />
      <TextInput
        {...register('lastName')}
        id="lastName"
        name="lastName"
        title="Your last name"
        placeholder="Last name"
        error={errors.lastName?.message}
      />
      <TextInput
        {...register('phone')}
        id="phone"
        name="phone"
        title="Your phone number"
        placeholder="Phone"
        error={errors.phone?.message}
      />
      <SensitiveTextInput
        {...register('password')}
        id="password"
        name="password"
        title="Your password"
        placeholder="Password"
        error={errors.password?.message}
      />
      <SensitiveTextInput
        {...register('confirmPassword')}
        id="confirmPassword"
        name="confirmPassword"
        title="Confirm your password"
        placeholder="Confirm Password"
        error={errors.confirmPassword?.message}
      />
      <div className="flex flex-row items-center">
        <p className="text-white">
          By signing up you agree to our{' '}
          <a id="privacy-policy-link" className="text-link" href={`${import.meta.env.VITE_MARKETING_URL}/privacy`}>
            Privacy Policy
          </a>
        </p>
      </div>
      <Button id="register-submit" loading={isSubmitting} type="submit">
        Register
      </Button>
    </form>
  );
};

export default RegisterForm;
