import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading, NewSelect, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { Address, CreateAddress, Property } from '@epcbuilder/lib/models/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useUserCompany from '@/hooks/company/useUserCompany';
import { getPropertyAddressCheck, postProperties } from '@/network/properties';

class MatchError extends Error {
  match: boolean;
  property: Property;

  constructor({ match, property }: { match: boolean; property: Property }) {
    super();
    this.match = match;
    this.property = property;
  }
}

const addressConfirmationSchema = yup.object().shape({
  addressLine1: yup.string().required('Address Line 1 must not be empty'),
  addressLine2: yup.string().optional(),
  city: yup.string().required('City must not be empty'),
  postcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
  ownerStatusId: yup.number().required('Owner status is required').min(1, 'Owner Status must not be empty'),
});

const AddressConfirmationForm = ({
  address,
  setAddress,
  onClose,
}: {
  address: Address;
  setAddress: Dispatch<SetStateAction<Address | undefined>>;
  onClose: () => void;
}) => {
  const [duplicate, setDuplicate] = useState<Property | undefined>(undefined);
  const [bypassDuplicate] = useState<boolean>(false);

  const { statusOptions, isLoading: statusOptionsLoading } = usePropertyOwnerStatuses();
  const defaultValues: CreateAddress = {
    addressLine1: address.line1,
    addressLine2: address.line2,
    city: address.city,
    postcode: address.postalCode,
    ownerStatusId: 0,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    watch,
    setValue,
  } = useForm<CreateAddress>({
    defaultValues,
    resolver: yupResolver(addressConfirmationSchema),
    reValidateMode: 'onSubmit',
  });
  const bypassEPC = watch('bypassEPC');
  const addressLine1 = watch('addressLine1');
  const addressLine2 = watch('addressLine2');
  const city = watch('city');
  const postcode = watch('postcode');
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
  const { company } = useUserCompany();

  const onSubmit: SubmitHandler<CreateAddress> = useCallback(
    async (data) => {
      try {
        if (!bypassDuplicate) {
          const check = await getPropertyAddressCheck({
            postcode: data.postcode.trim(),
            addressLine1: data.addressLine1,
          });
          if (check.matchFound) throw new MatchError({ match: check.matchFound, property: check.matchedProperty });
        }
        await postProperties({
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          city: data.city,
          postcode: data.postcode.trim(),
          bypassEPC: data.bypassEPC,
          ownerStatusId: data.ownerStatusId,
        });
        toast.success('Property successfully created', { toastId: 'create-property-success' });

        if (data.ownerStatusId == 2 && company && company.id === -1) {
          //Landlord / Managing Agent and user not assigned to any company
          setShowCompanyModal(true);
        } else {
          onClose();
        }
      } catch (error: unknown) {
        const { errors, detail } = error as AxiosErrorData;
        handleFormErrors<CreateAddress>(setError, errors);

        if (error instanceof MatchError) {
          if (error.match) setDuplicate(error.property);
        }

        switch (detail) {
          case 'EPC not found':
            setValue('bypassEPC', true);
            break;
          default:
            handleUnknownDetail(error);
            break;
        }
      }
    },
    [bypassDuplicate, company, onClose, setError, setValue]
  );

  useEffect(() => {
    if (bypassDuplicate) {
      handleSubmit(onSubmit)();
    }
  }, [bypassDuplicate, handleSubmit, onSubmit]);

  if (isSubmitting || statusOptionsLoading || !statusOptions) {
    return <Loading />;
  }

  if (showCompanyModal) {
    return (
      <div>
        <p className="mt-4">
          Your property has been successfully added! As a landlord or managing agent, you may be interested in using our
          ‘Companies’ page. If you’re using EPC Builder at work, contact us to see how we can make things easier for
          you.
        </p>
        <p className="mt-4 flex flex-row gap-3">
          <NewButton
            id="take-me-there-button"
            onClick={() => window.location.replace('/company')}
            text="Take me there"
            variant="secondary"
          />
          <NewButton id="no-thanks-button" onClick={onClose} text="No Thanks" />
        </p>
      </div>
    );
  }

  if (bypassEPC) {
    return (
      <div id="no-epc-error" className="mt-2 flex flex-col">
        <h1 className="mb-4">We are unable to locate an EPC rating for address:</h1>
        <p>{addressLine1}</p>
        {addressLine2 && <p>{addressLine2}</p>}
        <p>{city}</p>
        <p>{postcode}</p>
        <div className="mt-4 flex flex-row justify-between gap-4">
          <NewButton
            variant="secondary"
            id="amend-address-button"
            text="Edit Address"
            onClick={() => {
              setAddress(undefined);
            }}
          />
          <NewButton text="Confirm Address" type="submit" id="bypass-epc-button" onClick={handleSubmit(onSubmit)} />
        </div>
        <p className="mt-4">If you have recently had an EPC done, please contact us on 0800 058 4140.</p>
      </div>
    );
  }

  if (duplicate) {
    return (
      <div id="duplicate-error" className="mt-4 flex flex-col gap-4">
        <h1>Potential duplicate property found:</h1>
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col">
            <p className="mb-2 font-bold">New</p>
            <p>{addressLine1}</p>
            {addressLine2 && <p>{addressLine2}</p>}
            <p>{city}</p>
            <p>{postcode}</p>
          </div>
          <div className="flex flex-1 flex-col">
            <p className="mb-2 font-bold">Duplicate</p>
            <p>{duplicate.addressLine1}</p>
            {duplicate.addressLine2 && <p>{duplicate.addressLine2}</p>}
            <p>{duplicate.city}</p>
            <p>{duplicate.postcode}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <NewButton
            variant="secondary"
            type="button"
            id="amend-address-button"
            text="Amend Property Address"
            onClick={() => {
              setAddress(undefined);
            }}
          />

          <Link className="w-full" to={`/properties/${duplicate.id}`}>
            <NewButton id="view-duplicate-button" text="View Property" />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-2 flex flex-col gap-2">
        <h1 className="text-2xl">Step 2: Confirm Your Property Details</h1>
        <p>Confirm your ownership and address details so we know we've got the right property.</p>
      </div>
      <form id="address-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <p className="font-bold">Owner Status</p>
          <NewSelect
            control={control}
            id="ownerStatusId"
            name="ownerStatusId"
            title="Select owner status"
            placeholder="Select owner status"
            error={errors.ownerStatusId?.message}
            options={statusOptions}
            className="rounded-[10px]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold">Address</p>
          <TextInput
            {...register('addressLine1')}
            id="addressLine1"
            name="addressLine1"
            title="The first line for your address"
            placeholder="Address Line 1"
            error={errors.addressLine1?.message}
          />
          <TextInput
            {...register('addressLine2')}
            id="addressLine2"
            name="addressLine2"
            title="The second line for your address"
            placeholder="Address Line 2"
            error={errors.addressLine2?.message}
          />
          <TextInput
            {...register('city')}
            id="city"
            name="city"
            title="Your city"
            placeholder="City"
            error={errors.city?.message}
          />
          <TextInput
            {...register('postcode')}
            id="postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Postcode"
            error={errors.postcode?.message}
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <NewButton
            type="button"
            id="search-again-button"
            className="text-link mt-4 text-center"
            onClick={() => {
              setAddress(undefined);
            }}
            text="Search Again"
            variant="secondary"
          />
          <NewButton id="create-property-submit" loading={isSubmitting} type="submit" text="Create Property" />
        </div>
      </form>
    </div>
  );
};

export default AddressConfirmationForm;
