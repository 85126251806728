import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loading, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { Address, AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddressesForPostcode, getAddressRetrieve } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
});

const defaultValues: PostcodeSearch = {
  postcode: '',
};

const PostcodeSearchForm = ({ setAddress }: { setAddress: Dispatch<SetStateAction<Address | undefined>> }) => {
  const [results, setResults] = useState<AddressResult[]>([]);
  const [addressId, setAddressId] = useState<string>('');
  const [showNoAddressesMessage, setShowNoAddressesMessage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = async ({ postcode }: PostcodeSearch) => {
    try {
      setShowNoAddressesMessage(false);
      const response = await getAddressesForPostcode({ postcode: postcode.trim() });
      if (response.length === 0) {
        setShowNoAddressesMessage(true);
        return;
      }
      setResults(response);
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<PostcodeSearch>(setError, errors);

      switch (detail) {
        case 'No addresses could be found':
        case 'Failed getting address':
          toast.error('There was an error, please check your postcode and try again', { toastId: 'query-error' });
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  const getFullAddress = useCallback(async () => {
    try {
      const address = await getAddressRetrieve({ id: addressId });
      setAddress(address[0]);
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'No addresses could be found':
        case 'Failed getting address':
          toast.error('There was an error, please check your postcode and try again', { toastId: 'retrieve-error' });
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  }, [addressId, setAddress]);

  useEffect(() => {
    if (addressId) {
      getFullAddress();
    }
  }, [addressId, getFullAddress]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <>
      <form id="postcode-form" className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl">Step 1: Select Your Address</h1>
          <p>Find your property to begin your EPC Builder journey.</p>
        </div>
        <div className="flex flex-col gap-1">
          <TextInput
            {...register('postcode')}
            id="postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Postcode"
            error={errors.postcode?.message}
          />
          {showNoAddressesMessage && (
            <p role="alert" className="text-error text-center text-sm">
              No addresses were found. Please click below to enter your address manually.
            </p>
          )}
          {results.length > 0 && (
            <div className="border-blue dark:border-primary-darker bg-color-secondary overflow-y-hidden rounded-[24px] border-2 shadow">
              <div className="no-scrollbar flex h-48 flex-col overflow-y-auto">
                {results.map((item) => (
                  <button
                    type="button"
                    key={item.id}
                    id={`${item.id}-address`}
                    className={`hover:bg-blue dark:hover:bg-primary-darker cursor-pointer p-2.5 text-left ${item.id === addressId ? 'bg-blue dark:bg-primary-darker hover:bg-' : ''}`}
                    onClick={() => setAddressId(item.id)}
                  >
                    {item.text}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <NewButton
            id="enter-manually-button"
            text="Set Manually"
            variant="secondary"
            onClick={() => {
              setAddress({ line1: '', line2: '', city: '', postalCode: '' } as Address);
            }}
          />
          <NewButton id="postcode-submit" variant="primary" text="Continue" type="submit" />
        </div>
      </form>
    </>
  );
};

export default PostcodeSearchForm;
