import React from 'react';
import { MdOutlinePrint, MdOutlineSaveAlt } from 'react-icons/md';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import download from 'downloadjs';
import { useJobContext } from '@/context/useJobContext';
import { getJobReport } from '@/network/jobs';

export const DownloadPdfCard = () => {
  const { job } = useJobContext();

  const downloadPdf = async () => {
    try {
      const pdfData = await getJobReport({ id: job?.id });

      download(pdfData?.file, pdfData?.fileName, 'application/pdf');
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <button
      id="download-pdf-button"
      className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm relative flex size-full cursor-pointer flex-col gap-4 rounded-[22px] p-4"
      onClick={downloadPdf}
    >
      <div className="flex size-full flex-col items-center justify-center gap-2">
        <MdOutlineSaveAlt size={32} className="text-color-brand" />
        <p className="text-center font-bold">Download PDF</p>
      </div>
    </button>
  );
};

export const PrintCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      id="request-callback-button"
      onClick={onClick}
      className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm relative flex size-full cursor-pointer flex-col gap-4 rounded-[22px] p-6"
    >
      <div className="flex size-full flex-col items-center justify-center gap-2">
        <MdOutlinePrint size={32} className="text-color-brand" />
        <p className="text-center font-bold">Print</p>
      </div>
    </button>
  );
};
