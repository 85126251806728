import { CouncilTaxBand } from '@epcbuilder/lib/models/properties';
import { PostcodeAttributes } from '@/hooks/properties/usePostcodeAttributes';
import { EligibilityFormData } from './eligibility-modal/EligibilityModal';

/**
 * Enum names correspond the "Name" value of records in the "Schemes" table
 */
export enum GrantSchemes {
  BoilerUpgrade = 'Boiler Upgrade Scheme (BUS)',
  GreatBritishInsulation = 'Great British Insulation Scheme (GBIS)',
  EnergyCompanyObligation4 = 'Energy Company Obligation (ECO4)',
  EcoFlex = 'ECO4 LA Flex',
}

type GBISCriteria = {
  ownerOccupiesProperty: boolean;
  councilTaxBand?: CouncilTaxBand;
};

export type QualifyingCriteriaContext = Omit<EligibilityFormData, 'consent'> & PostcodeAttributes & GBISCriteria;

const hasEco4Benefit = (qc: QualifyingCriteriaContext): boolean => {
  return qc.benefits !== undefined && qc.benefits.length >= 1 && !qc.benefits.includes('noneoftheabove');
};

const qualifiesForEco4 = (qc: QualifyingCriteriaContext): boolean => {
  return hasEco4Benefit(qc);
};

const ecoFlexRoute1 = (qc: QualifyingCriteriaContext): boolean => qc.incomeLess31k;

const ecoFlexRoute2 = (qc: QualifyingCriteriaContext): boolean => qc.flexCriteria.includes('CriticalIllness');

const ecoFlexRoute3 = (qc: QualifyingCriteriaContext): boolean => {
  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('FreeSchoolMeals')) {
    return true;
  }

  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.flexCriteria.includes('FreeSchoolMeals') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.isFlexMst) {
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('NICE')) {
      return true;
    }
  }

  if (qc.isFlexLsoa) {
    if (qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
  }

  return false;
};

const qualifiesForEcoFlex = (qc: QualifyingCriteriaContext): boolean => {
  if (!qc.isFlexEligible) {
    return false;
  }

  const qualifiesViaRoute1 = ecoFlexRoute1(qc);
  const qualifiesViaRoute2 = ecoFlexRoute2(qc);
  const qualifiesViaRoute3 = ecoFlexRoute3(qc);

  return qualifiesViaRoute1 || qualifiesViaRoute2 || qualifiesViaRoute3;
};

const qualifiesForBoilerUpgradeScheme = (_qc: QualifyingCriteriaContext): boolean => true;

const qualifiesForGreatBritainInsulationScheme = (qc: QualifyingCriteriaContext): boolean => {
  const inEligibleCouncilTaxBand = (band?: CouncilTaxBand) => {
    const eligibleBands: CouncilTaxBand[] = ['A', 'B', 'C', 'D'];

    if (!band) return false;
    return eligibleBands.includes(band);
  };

  const qualifiesViaRoute1 = hasEco4Benefit(qc);
  const qualifiesViaRoute2 = ecoFlexRoute1(qc) || ecoFlexRoute2(qc) || ecoFlexRoute3(qc);
  const qualifiesViaRoute3 = inEligibleCouncilTaxBand(qc.councilTaxBand) && qc.ownerOccupiesProperty;

  return qualifiesViaRoute1 || qualifiesViaRoute2 || qualifiesViaRoute3;
};

const qualifyingFunctions: Record<GrantSchemes, (qc: QualifyingCriteriaContext) => boolean> = {
  [GrantSchemes.EcoFlex]: qualifiesForEcoFlex,
  [GrantSchemes.EnergyCompanyObligation4]: qualifiesForEco4,
  [GrantSchemes.BoilerUpgrade]: qualifiesForBoilerUpgradeScheme,
  [GrantSchemes.GreatBritishInsulation]: qualifiesForGreatBritainInsulationScheme,
};

export const findQualifyingSchemes = (qc: QualifyingCriteriaContext): GrantSchemes[] => {
  const schemes: GrantSchemes[] = [];

  Object.entries(qualifyingFunctions).forEach(([scheme, qualifyFn]) => {
    if (qualifyFn(qc)) {
      schemes.push(scheme as GrantSchemes);
    }
  });

  return schemes;
};
