import React, { useMemo, useState } from 'react';
import { Modal, SimpleProgressBar } from '@epcbuilder/lib/components';
import { Address } from '@epcbuilder/lib/models/properties';
import { FormStage } from '@/models/properties';
import AddressConfirmationForm from './AddressConfirmationForm';
import PostcodeSearchForm from './PostcodeSearchForm';

const CreateNewPropertyModal = ({ onClose }: { onClose: () => void }) => {
  const [address, setAddress] = useState<Address>();

  const formStage = useMemo(() => {
    return address ? FormStage.ADDRESS : FormStage.POSTCODE;
  }, [address]);

  return (
    <Modal
      id="create-property-modal"
      onClose={onClose}
      className="bg-light-dark hide-scrollbar px-4 py-10 sm:h-auto sm:max-h-[80vh] sm:w-3/4 sm:min-w-[600px] sm:max-w-[728px] sm:rounded-[20px] sm:p-12"
    >
      <div>
        <SimpleProgressBar progress={((formStage + 1) / (Object.keys(FormStage).length / 2 + 1)) * 100} />
        {formStage === FormStage.ADDRESS && address ? (
          <AddressConfirmationForm address={address} setAddress={setAddress} onClose={onClose} />
        ) : (
          <PostcodeSearchForm setAddress={setAddress} />
        )}
      </div>
    </Modal>
  );
};

export default CreateNewPropertyModal;
