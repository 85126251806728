import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCustomerBankAccounts from '@/hooks/bankAccounts/useCustomerBankAccounts';
import { PaymentDetails } from '@/models/payments';
import { deleteCustomerBankAccount, postCustomerBankAccount } from '@/network/customers';
import { formatAccountNumber, formatSortCode } from '@/utils/inputFormatters';
import BillingDetails from '../epc-wizard/steps/energy-assessment/BillingDetails';

const paymentDetailsSchema = yup.object().shape({
  accountHolderName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid Account name')
    .required('Account name must not be empty'),
  accountNumber: yup
    .string()
    .required('Account number must not be empty')
    .matches(/^\d{8}$/, 'Account number must be 8 digits'),
  sortCode: yup
    .string()
    .required('Sort code must not be empty')
    .matches(/^\d{2}-\d{2}-\d{2}$/, 'Sort code must be in the format 00-00-00'),
});

const PaymentMethods = ({ id }: { id: string }) => {
  const [currentForm, setCurrentForm] = useState<'savedMethods' | 'BillingDetails' | 'paymentDetails'>('savedMethods');

  const { bankAccountData, isLoading, mutate } = useCustomerBankAccounts();
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PaymentDetails>({ resolver: yupResolver(paymentDetailsSchema) });

  const clearNewPaymentMethodValues = () => {
    setValue('accountHolderName', '');
    setValue('accountNumber', '');
    setValue('sortCode', '');
  };

  const createBankAccount = async (paymentDetails: PaymentDetails) => {
    try {
      await postCustomerBankAccount(paymentDetails);
      clearNewPaymentMethodValues();
      mutate();
      setCurrentForm('savedMethods');
    } catch (error) {
      const { detail } = error as AxiosErrorData;
      if (detail) {
        const displayMessage = detail.split('  ')[0];
        const userMessage =
          displayMessage === 'Validation failed'
            ? 'Invalid bank details. Please check your account number and sort code.'
            : displayMessage;

        setError('accountHolderName', {
          type: 'manual',
          message: userMessage,
        });
      } else {
        setError('accountHolderName', {
          type: 'manual',
          message: 'An unexpected error occurred. Please try again.',
        });
      }

      handleUnknownDetail(error);
    }
  };

  const deleteBankAccount = async (bankAccountId: string) => {
    try {
      await deleteCustomerBankAccount(bankAccountId);
      mutate();
    } catch (error) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id={id}>
      <div className="border-neutral mb-4 border-b text-start sm:mb-0 dark:border-[#516B75]">
        <h1 className="mb-2 font-semibold uppercase sm:font-bold">Payment Methods</h1>
        <p className="pb-4 font-[14px] sm:font-[18px]">Securely store and manage your preferred payment options.</p>
      </div>

      {currentForm === 'savedMethods' && (
        <div className="items-left flex flex-col justify-center sm:mt-6">
          <div className="max-w-2xl">
            {bankAccountData && bankAccountData.length > 0 ? (
              <>
                <p className="font-bold" id="choose-payment-method">
                  Your saved payment methods
                </p>
                {bankAccountData.map(({ bankAccountId, accountHolder, accountNumberEnding }) => (
                  <div
                    key={bankAccountId}
                    id={bankAccountId}
                    className="border-blue-lighter dark:border-primary-darker bg-color-secondary relative mt-2 flex flex-col rounded-xl border-2 p-4 md:flex-row md:items-center md:justify-between"
                  >
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row items-center gap-2">
                        <strong>Account Holder:</strong>
                        <p>{accountHolder.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}</p>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <strong>Account Number:</strong>
                        <p>***{accountNumberEnding}</p>
                      </div>
                    </div>

                    <button
                      id="remove-button"
                      className="mt-4 self-end font-bold hover:opacity-75 md:mt-0 md:self-center"
                      onClick={() => deleteBankAccount(bankAccountId)}
                    >
                      Remove
                    </button>
                  </div>
                ))}

                <div className="mb-10 mt-6 flex w-full flex-col gap-4 sm:w-64 lg:mb-2">
                  <NewButton
                    id="add-new-payment-method-button"
                    text="Add New Payment Method"
                    onClick={() => setCurrentForm('BillingDetails')}
                  />
                </div>
              </>
            ) : (
              <div>
                <p className="mb-6 font-bold" id="choose-payment-method">
                  Your saved payment methods
                </p>
                <p id="no-payments-methods-message">You don’t have any saved payment method.</p>
                <div className="mb-10 mt-6 flex w-full flex-col gap-4 sm:w-64 lg:mb-2">
                  <NewButton
                    id="add-new-payment-method-button"
                    text="Add New Payment Method"
                    onClick={() => setCurrentForm('BillingDetails')}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {currentForm === 'BillingDetails' && (
        <div className="items-left justify-left flex">
          <div className="mt-4 w-full max-w-lg">
            <BillingDetails
              onNext={() => setCurrentForm('paymentDetails')}
              layoutClassName="w-full md:h-[500px] lg:h-full h-full"
              formClassName="mt-4 flex w-full max-w-2xl flex-col sm:mt-6 sm:h-[530px] pr-0 lg:pr-0 sm:pr-6 md:h-full h-full overflow-auto"
            />
          </div>
        </div>
      )}

      {currentForm === 'paymentDetails' && (
        <div className="items-left justify-left flex">
          <div className="w-full">
            <div className="items-left flex w-full flex-col sm:mt-4">
              <h1 id="bank-details-heading" className="font-header w-full text-left text-2xl">
                Bank Details
              </h1>
              <form className="mt-6 flex flex-col" onSubmit={handleSubmit(createBankAccount)}>
                <div className="relative">
                  <div className="mb-6 flex gap-4">
                    <TextInput
                      {...register('accountHolderName')}
                      id="account-holder-name"
                      label="Account Holder Name"
                      placeholder="Enter account holder name"
                      error={errors.accountHolderName?.message}
                    />
                  </div>
                  <div className="mb-6 flex gap-4">
                    <TextInput
                      {...register('accountNumber')}
                      id="account-number"
                      label="Account Number"
                      placeholder="12345678"
                      formatValue={formatAccountNumber}
                      error={errors.accountNumber?.message}
                    />
                  </div>
                  <div className="mb-6 flex gap-4">
                    <TextInput
                      {...register('sortCode')}
                      id="sort-code"
                      label="Sort Code"
                      placeholder="00-00-00"
                      formatValue={formatSortCode}
                      error={errors.sortCode?.message}
                    />
                  </div>
                </div>
                <div className="mb-10 mt-6 flex justify-between gap-4 sm:w-56 lg:mb-2">
                  <NewButton id="submit-form-button" variant="primary" text="Save Payment Method" type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
