import React, { ReactElement } from 'react';
import { MdEditCalendar } from 'react-icons/md';
import { atcb_action } from 'add-to-calendar-button';
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../context/themeContext';
import { NewButton } from '../Buttons';

interface IAddToCalendarButtonProps {
  id?: string;
  name?: string;
  location?: string;
  startDate: string;
  endDate: string;
  timeZone?: string;
  description?: string;
  containerClassName?: string;
}

const AddToCalendarModalButton = ({
  id,
  name,
  location,
  startDate,
  endDate,
  timeZone = 'Europe/London',
  description,
  containerClassName,
}: IAddToCalendarButtonProps): ReactElement => {
  const { theme } = useTheme();

  const baseContainerClassName = 'w-fit';
  const finalContainerClassName = twMerge(baseContainerClassName, containerClassName);

  const handleClick = () => {
    atcb_action({
      name: name,
      listStyle: 'modal',
      options: ['Google', 'Apple', 'Outlook.com', 'Microsoft365'],
      location: location,
      startDate: startDate,
      endDate: endDate,
      timeZone: timeZone,
      lightMode: theme === 'dark' ? 'dark' : 'light',
      description: description,
    });
  };

  return (
    <div className={finalContainerClassName}>
      <NewButton
        id={id || 'add-to-calendar-button'}
        onClick={handleClick}
        icon={<MdEditCalendar size={20} />}
        text="Add To Calendar"
      />
    </div>
  );
};

export default AddToCalendarModalButton;
