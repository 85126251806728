import React from 'react';
import { Card } from '@epcbuilder/lib/components/Layout';
import { Children } from '@epcbuilder/lib/utils';

const GenericNextStepsCard = ({ children, tooltipText }: { children: Children; tooltipText?: string }) => {
  return (
    <Card tooltipText={tooltipText} className="shadow-grey-sm cursor-default">
      <div>
        <p className="pb-2 text-[18px] font-black uppercase">
          Your <span className="text-primary">next steps</span>
        </p>
        {children}
      </div>
    </Card>
  );
};

export default GenericNextStepsCard;
