import { CouncilTaxBand } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getPropertyCouncilTaxBand } from '@/network/properties';

const usePropertyCouncilTaxBand = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<CouncilTaxBand>(
    id ? `property-${id}-council-tax-band` : null,
    async () => (id ? await getPropertyCouncilTaxBand({ id }) : null)
  );

  return {
    councilTaxBand: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyCouncilTaxBand;
