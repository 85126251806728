import React from 'react';
import { MdCheck, MdOutlineClose } from 'react-icons/md';
import { GrantSchemes } from '../ecoFlexQualifyingChecker';

interface IGrantScheme {
  name: string;
  detailsIfEligible: string;
  eligibilityState: 'eligible' | 'ineligible' | 'empty';
}

const EligibilityFormSchemeItem = ({ scheme }: { scheme: IGrantScheme }) => {
  const baseBoxClasses = 'border-1 rounded-sm';
  const borderClasses = `border-[#748991] dark:border-[#DCE1E3]`;

  const renderCheckbox = () => {
    switch (scheme.eligibilityState) {
      case 'eligible':
        return <MdCheck className={`${baseBoxClasses} ${borderClasses} text-primary`} size={24} />;
      case 'ineligible':
        return (
          <MdOutlineClose className={`${baseBoxClasses} ${borderClasses} text-secondary-dark border-1`} size={24} />
        );
      default:
        return <div className={`${baseBoxClasses} ${borderClasses} text-neutral-dark size-6`} />;
    }
  };

  return (
    <li className="flex flex-col">
      <div className="flex gap-2">
        {renderCheckbox()}
        <p className="font-normal">{scheme.name}</p>
      </div>
      <p className="mt-2 text-sm text-[#516B75] dark:text-[#EEF1F2]">
        {scheme.eligibilityState == 'ineligible' ? "Sorry, you don't qualify." : scheme.detailsIfEligible}
      </p>
    </li>
  );
};

const EligibilityResults = ({ eligibleFor }: { eligibleFor?: GrantSchemes[] }) => {
  const getEligibilityState = (scheme: GrantSchemes) => {
    if (eligibleFor === undefined) {
      return 'empty';
    }
    if (eligibleFor.includes(scheme)) {
      return 'eligible';
    }
    return 'ineligible';
  };

  const grantSchemes: Record<GrantSchemes, IGrantScheme> = {
    [GrantSchemes.BoilerUpgrade]: {
      name: 'Boiler Upgrade Scheme (BUS)',
      detailsIfEligible: 'Should you upgrade your gas boiler to an air source heat pump.',
      eligibilityState: getEligibilityState(GrantSchemes.BoilerUpgrade),
    },
    [GrantSchemes.GreatBritishInsulation]: {
      name: 'Great British Insulation Scheme',
      detailsIfEligible: 'Should you choose to install insulation and /or heating controls (subject to eligibility).',
      eligibilityState: getEligibilityState(GrantSchemes.GreatBritishInsulation),
    },
    [GrantSchemes.EnergyCompanyObligation4]: {
      name: 'Energy Company Obligation (ECO)',
      detailsIfEligible: 'Available for all insulation and renewables measures, fully funded!',
      eligibilityState: getEligibilityState(GrantSchemes.EnergyCompanyObligation4),
    },
    [GrantSchemes.EcoFlex]: {
      name: 'ECO Flex',
      detailsIfEligible: 'Available for all insulation and renewables measures, fully funded!',
      eligibilityState: getEligibilityState(GrantSchemes.EcoFlex),
    },
  };

  return (
    <div
      id="eligibility-schemes-list"
      className="border-1 dark:bg-dark rounded-lg border-[#DCE1E3] bg-white p-3 dark:border-[#516B75]"
    >
      <h2 className="mb-4 font-normal">You Might Be Eligible For:</h2>
      <ul className="flex flex-col gap-4">
        {Object.entries(grantSchemes).map(([_, details]) => (
          <EligibilityFormSchemeItem key={details.name} scheme={details} />
        ))}
      </ul>
    </div>
  );
};

export default EligibilityResults;
