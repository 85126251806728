import React, { useState } from 'react';
import { Card, EpcGraph } from '@epcbuilder/lib/components';
import { PropertyRating } from '@epcbuilder/lib/models/properties';
import { useBelowDesktop } from '@/hooks/media-queries/useMediaQueries';

const EpcGraphCard = ({
  propertyRatings,
  propertyRatingError,
}: {
  propertyRatings: PropertyRating | undefined;
  propertyRatingError: boolean;
}) => {
  const isMobile = useBelowDesktop();
  const [showEpcTooltip, setShowEpcTooltip] = useState<boolean>(false);

  return (
    <Card className="flex flex-col items-center md:flex-row-reverse xl:flex-col">
      <div className="relative w-full grow lg:w-1/2 xl:w-full">
        <EpcGraph propertyRating={propertyRatings?.currentRating} />
        {propertyRatingError && (
          <div
            id="property-no-rating"
            className="absolute left-0 top-0 flex size-full flex-row items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
          >
            <div className="bg-blue/90 dark:bg-primary-dark/90 max-w-[220px] rounded-[22px] p-4 shadow-lg">
              <p id="no-rating-card" className="font-bold">
                Your property does not have an EPC rating.
              </p>
            </div>
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={() => isMobile && setShowEpcTooltip(!showEpcTooltip)}
        className={`${showEpcTooltip ? 'rounded-[16px]' : 'rounded-[22px]'} ${
          isMobile ? 'cursor-pointer' : 'cursor-default'
        } bg-blue dark:bg-primary-dark relative flex size-full w-full grow flex-row items-center justify-center gap-2 lg:w-1/2 xl:w-full xl:flex-col`}
      >
        <p id="epc-tooltip" className="p-4 text-left text-[16px] lg:text-[18px]">
          An Energy Performance Certificate (EPC) shows how energy efficient your property is.
          <br />
          <br /> Properties are given a rating from A (most efficient) to G (least efficient).
          <br />
          <br /> The graph shows this property’s current energy efficiency.
        </p>
      </button>
    </Card>
  );
};

export default EpcGraphCard;
