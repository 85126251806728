import React, { Dispatch, SetStateAction, useState } from 'react';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { convertNumberToGbpString } from '@epcbuilder/lib/utils/string';
import useFinanceCalculator from '@/hooks/finance-calculator/useFinanceCalculator';
import { EpcWizardSteps, JobImprovementDetailsDto } from '@/models/job';
import { formatForId } from '@/utils/inputFormatters';
import { PlanDrawerStep } from './ImprovementPlansDrawer';

interface IOrderSummaryStepProps {
  setPlanDrawerStep: Dispatch<SetStateAction<PlanDrawerStep>>;
  closeDrawer: () => void;
  setActiveWizardStep: Dispatch<SetStateAction<EpcWizardSteps>>;
  selectedPlan?: JobImprovementDetailsDto;
}

enum PaymentOption {
  Finance = 'Finance',
  Cash = 'Cash',
}

const OrderSummaryStep = ({
  setPlanDrawerStep,
  closeDrawer,
  setActiveWizardStep,
  selectedPlan,
}: IOrderSummaryStepProps) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption>(PaymentOption.Finance);

  const {
    initialPaymentPounds,
    reversionPaymentPounds,
    fullTermCostPounds,
    totalInterestCostPounds,
    productFeePounds,
    annualPercentageRateOfCharge,
    initialApr,
  } = useFinanceCalculator({
    financeCostPounds: selectedPlan?.totalCostPounds ?? 0,
  });

  const proceedBasedOnSelectedPayment = () => {
    switch (selectedPaymentOption) {
      case PaymentOption.Finance:
        setPlanDrawerStep(PlanDrawerStep.FinanceService);
        break;
      case PaymentOption.Cash:
        closeDrawer();
        setActiveWizardStep(EpcWizardSteps.INSTALL);
        break;
    }
  };

  if (!selectedPlan) {
    return (
      <div className="flex max-w-[576px] flex-col items-start gap-6">
        <h2>No plan found</h2>
      </div>
    );
  }

  const selectedPlanNameId = `selected-${formatForId(selectedPlan.planName)}`;

  return (
    <div className="flex max-w-[576px] flex-col items-start gap-6">
      <div id="drawer-heading">
        <h2 className="mb-4 text-2xl">Order Summary</h2>
        <h3 id={selectedPlanNameId} className="mb-1 text-xl font-medium">
          {selectedPlan.planName}
        </h3>
        <p className="mb-3">
          New EPC Rating:{' '}
          <span id={'new-epc-rating'} className="font-bold">
            {selectedPlan.newEpcRating}
          </span>
        </p>
      </div>

      <div id="order-items-summary" className="w-full">
        <div className="mb-4">
          {selectedPlan.improvementItems.map((planItem, index) => (
            <div key={planItem.itemName} className="my-1 flex justify-between text-[#264653] dark:text-white">
              <p id={`item-name-${index}`} className="text-sm">
                {planItem.itemName}
              </p>
              <p id={`item-cost-${index}`} className="text-sm">
                {convertNumberToGbpString(planItem.itemCostPounds)}
              </p>
            </div>
          ))}
        </div>

        <Divider />
        <div className="flex justify-end">
          <p className="font-bold">
            Total Cost:{' '}
            <span id="total-cost" className="font-normal">
              {convertNumberToGbpString(selectedPlan.totalCostPounds)}
            </span>
          </p>
        </div>
      </div>

      <div id="payment-options">
        <h2 className="mb-1 text-2xl">Payment Options</h2>
        <p className="font-jost mb-3 text-[#516B75] dark:text-[#DCE1E3]">Select the one that's best for you.</p>

        <div className="flex flex-col gap-2">
          <PaymentOptionCard
            paymentType={PaymentOption.Finance}
            selectedPaymentType={selectedPaymentOption}
            setSelectedPaymentOption={setSelectedPaymentOption}
          >
            <p className="mb-1 font-sans text-base text-[#516B75] dark:text-[#DCE1E3]">
              {`A loan of ${convertNumberToGbpString(selectedPlan.totalCostPounds)} over 25 years results in 60 monthly payments of ${convertNumberToGbpString(initialPaymentPounds)} at a fixed annual rate of
            ${initialApr}% and 240 monthly payments of ${convertNumberToGbpString(reversionPaymentPounds)} at a reversion rate of 3.14% above the Bank of England Base
            Rate. The total cost over the full term is ${convertNumberToGbpString(fullTermCostPounds)}, including interest of ${convertNumberToGbpString(totalInterestCostPounds)}, a product fee
            of ${convertNumberToGbpString(productFeePounds)} added to the balance. APRC: ${annualPercentageRateOfCharge}%.`}
            </p>
            <p className="leading-7 text-[#516B75] dark:text-[#DCE1E3]">
              Financing provided by Selina Finance. See our{' '}
              <a
                className="font-bold underline"
                target="_blank"
                href={`${import.meta.env.VITE_MARKETING_URL}/finance-and-funding`}
                rel="noreferrer"
              >
                Finance Options
              </a>{' '}
              for more information.
            </p>
          </PaymentOptionCard>
          <PaymentOptionCard
            paymentType={PaymentOption.Cash}
            selectedPaymentType={selectedPaymentOption}
            setSelectedPaymentOption={setSelectedPaymentOption}
          >
            <p id="total-cost" className="font-jost mb-1 text-[#516B75] dark:text-[#DCE1E3]">
              {convertNumberToGbpString(selectedPlan.totalCostPounds)}
            </p>
            <p className="leading-7 text-[#516B75] dark:text-white">
              Please note we will not take any payment from you at this stage. Our team will be in touch.
            </p>
          </PaymentOptionCard>
        </div>
      </div>
      <div className="flex w-full gap-2" id="drawer-navigation">
        <NewButton
          id="back-to-plan-selection"
          onClick={() => setPlanDrawerStep(PlanDrawerStep.SelectYourPlan)}
          text="Back"
          variant="secondary"
        />
        <NewButton id="continue-with-payment-method" onClick={() => proceedBasedOnSelectedPayment()} text="Continue" />
      </div>
    </div>
  );
};

export default OrderSummaryStep;

interface IPaymentOptionCardProps {
  paymentType: PaymentOption;
  selectedPaymentType: PaymentOption;
  setSelectedPaymentOption: Dispatch<SetStateAction<PaymentOption>>;
  children?: React.ReactNode;
}

const PaymentOptionCard = ({
  paymentType,
  selectedPaymentType,
  setSelectedPaymentOption,
  children,
}: IPaymentOptionCardProps) => {
  const isSelected = paymentType === selectedPaymentType;
  const cardClassList = `${isSelected ? 'dark:bg-dark-dark bg-[#F5FFFD]' : 'dark:bg-[#264653] bg-white'} dark:shadow-dark-sm border-1 shadow-grey-sm max-h-fit w-full gap-4 rounded-[20px] border-[#DCE1E3] p-6 dark:border-[#516B75]`;

  return (
    <button
      id={`option-${paymentType.toLowerCase()}`}
      onClick={() => setSelectedPaymentOption(paymentType)}
      className={`flex w-auto flex-row border-2 p-6 text-start ${cardClassList}`}
    >
      <input id={`${paymentType.toLowerCase()}-radio`} type="radio" className="size-6 shrink-0" checked={isSelected} />
      <div className="flex flex-col">
        <h3 className="mb-2 text-xl font-medium text-[#264653] dark:text-white">{paymentType}</h3>
        {children}
      </div>
    </button>
  );
};

const Divider = ({ verticalPaddingClasses = 'my-2' }: { verticalPaddingClasses?: string }) => {
  return <div className={`${verticalPaddingClasses} h-[1px] bg-[#DCE1E3] dark:bg-[#516B75]`} />;
};
