import React, { useCallback, useEffect, useState } from 'react';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { Job, JobStatus } from '@epcbuilder/lib/models/jobs';
import { useJobContext } from '@/context/useJobContext';
import { useJobImprovementsContext } from '@/context/useJobImprovementsContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import { EpcWizardSteps, JobImprovementDetailsDto } from '@/models/job';
import EnergyAssessmentStep from './steps/energy-assessment';
import EpcRatingStep from './steps/epc-rating';
import ImprovementPlanStep from './steps/improvement-plan';
import InstallationStep from './steps/install';
import JobComplete from './steps/job-complete';
import EpcWizardStepper from './Stepper';

const mapJobStatusToEpcWizardStep = (job?: Job, jobImprovements?: Array<JobImprovementDetailsDto>): EpcWizardSteps => {
  switch (job?.jobStatusID) {
    case JobStatus.NEW:
    case JobStatus.PENDING:
      return EpcWizardSteps.EPC_RATING;
    case JobStatus.SURVEY:
      return EpcWizardSteps.ENERGY_ASSESSMENT;
    case JobStatus.REPORT:
      return EpcWizardSteps.IMPROVEMENT_PLAN;
    case JobStatus.INSTALLATION:
      return jobImprovements && jobImprovements.find((x) => x.isSelected)
        ? EpcWizardSteps.INSTALL
        : EpcWizardSteps.IMPROVEMENT_PLAN;
    case JobStatus.COMPLETE:
      return EpcWizardSteps.JOB_COMPLETE;
    default:
      return EpcWizardSteps.EPC_RATING;
  }
};

const EpcWizard = () => {
  const { property } = usePropertyContext();
  const { job } = useJobContext();
  const { jobImprovements } = useJobImprovementsContext();

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [active, setActive] = useState<EpcWizardSteps>(mapJobStatusToEpcWizardStep(job, jobImprovements));

  useEffect(() => {
    if (job && jobImprovements && firstLoad) {
      setFirstLoad(false);
      setActive(mapJobStatusToEpcWizardStep(job, jobImprovements));
    }
  }, [job, jobImprovements, firstLoad]);

  interface EpcWizardStepStatus {
    value: EpcWizardSteps;
    locked: boolean;
    completed: boolean;
  }

  const mapJobStatusToEpcWizardSteps = (
    jobStatus: JobStatus,
    jobImprovements?: Array<JobImprovementDetailsDto>
  ): Array<EpcWizardStepStatus> => {
    const numericValues = Object.keys(EpcWizardSteps)
      .filter((key) => isNaN(Number(key))) // Filter out keys that can be converted to numbers
      .map((key) => EpcWizardSteps[key as keyof typeof EpcWizardSteps]);

    const steps = numericValues.map((value) => ({
      value,
      locked: false,
      completed: false,
    }));

    switch (jobStatus) {
      case JobStatus.NEW:
      case JobStatus.PENDING:
        return steps.map((step) => ({
          ...step,
          locked: step.value > EpcWizardSteps.EPC_RATING,
        }));
      case JobStatus.SURVEY:
        return steps.map((step) => ({
          ...step,
          completed: step.value < EpcWizardSteps.ENERGY_ASSESSMENT,
          locked: step.value > EpcWizardSteps.ENERGY_ASSESSMENT,
        }));
      case JobStatus.REPORT:
      case JobStatus.INSTALLATION:
        return steps.map((step) => ({
          ...step,
          completed:
            step.value <
            (jobImprovements?.find((x) => x.isSelected) ? EpcWizardSteps.INSTALL : EpcWizardSteps.IMPROVEMENT_PLAN),
          locked:
            step.value >
            (jobImprovements?.find((x) => x.isSelected) ? EpcWizardSteps.INSTALL : EpcWizardSteps.IMPROVEMENT_PLAN),
        }));
      case JobStatus.COMPLETE:
        return steps.map((step) => ({
          ...step,
          completed: step.value <= EpcWizardSteps.JOB_COMPLETE,
        }));
    }

    return steps;
  };

  const renderStep = useCallback(() => {
    switch (active) {
      case EpcWizardSteps.EPC_RATING:
        return <EpcRatingStep setActive={setActive} />;
      case EpcWizardSteps.ENERGY_ASSESSMENT:
        return <EnergyAssessmentStep />;
      case EpcWizardSteps.IMPROVEMENT_PLAN:
        return <ImprovementPlanStep setActiveWizardStep={setActive} />;
      case EpcWizardSteps.INSTALL:
        return <InstallationStep />;
      case EpcWizardSteps.JOB_COMPLETE:
        return <JobComplete job={job} />;
      default: {
        const _exhaustiveCheck: never = active;
        return _exhaustiveCheck;
      }
    }
  }, [active, job]);

  if (!job) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <div>
      <div className="mb-6 flex flex-col gap-6 lg:flex-row">
        <h1
          id="addressDisplay"
          className="px-6 font-extrabold uppercase"
        >{`${property?.addressLine1}${property?.addressLine2 ? `, ${property.addressLine2}` : ''}, ${property?.city}, ${property?.postcode}`}</h1>
      </div>
      <EpcWizardStepper
        epcWizardSteps={mapJobStatusToEpcWizardSteps(job.jobStatusID, jobImprovements)}
        active={active}
        setActive={setActive}
      />
      {renderStep()}
    </div>
  );
};

export default EpcWizard;
