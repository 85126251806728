import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { MdKeyboardArrowLeft, MdOutlineArrowBack } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import Layout from '@/components/Layout';
import { Property } from '@/components/property';
import withAuth from '@/hoc/withAuth';
import usePropertyJob from '@/hooks/properties/usePropertyJob';

const PropertyPage = () => {
  const params = useParams();

  const { job, error: jobError } = usePropertyJob({ id: params.id });
  const renderJob = useCallback(() => {
    if (jobError) {
      return (
        <div
          className="mt-[12rem] flex flex-col lg:mt-0 lg:flex-row lg:items-center lg:justify-center lg:gap-8"
          style={{ height: 'calc(100vh - 100px)' }}
        >
          <div className=" flex flex-col items-start lg:flex-row lg:gap-4">
            <img src="/images/error-parachute.png" alt="Error Parachute" className="w-40 lg:mb-0" />
            <img
              src="/images/404.png"
              alt="404 Decoration"
              className="absolute inset-x-0 z-0 mx-auto mb-4 mt-10 lg:bottom-0 lg:right-0 lg:order-last lg:mb-0 lg:ml-auto lg:mr-0 lg:h-[60%] lg:w-[90%]"
            />
          </div>
          <div className="z-10 ml-4 text-left lg:ml-0">
            <h1 className="text-2xl font-semibold" id="not-found-message">
              Oops! Page Not Found
            </h1>
            {jobError.status === 500 ? (
              <p className="mt-2 text-lg">Something went wrong, please try again later.</p>
            ) : (
              <p className="mt-2 text-lg">The page you’re looking for doesn’t seem to exist.</p>
            )}

            <div className="mt-4 w-52">
              <Link to={'/properties'}>
                <NewButton id="back-to-properties" text="Back To Properties" icon={<MdOutlineArrowBack />} />
              </Link>
            </div>
          </div>
        </div>
      );
    }

    if (!job) {
      return (
        <FullPage>
          <Loading />
        </FullPage>
      );
    }

    return <Property jobId={job.id} propertyId={job.propertyId} />;
  }, [job, jobError]);

  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Property">
        <div className="flex flex-col p-4 sm:p-8">
          {!jobError && (
            <Link to="/properties" className="flex flex-row items-center gap-2">
              <MdKeyboardArrowLeft size={20} />
              <p>Back</p>
            </Link>
          )}
          {renderJob()}
        </div>
      </Layout>
    </>
  );
};

export default withAuth(PropertyPage);
