import React, { useEffect, useState } from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { MdBookmarkAdded } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Loading } from '@epcbuilder/lib/components';
import AddToCalendarModalButton from '@epcbuilder/lib/components/add-to-calendar/AddToCalendarButton';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { BookingSlot, BookingSlotTimeframe, JobSurvey, SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { RefundReason } from '@epcbuilder/lib/models/payments';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { differenceInHours, format } from 'date-fns';
import { usePropertyContext } from '@/context/usePropertyContext';
import useUser from '@/hooks/auth/useUser';
import { useBelowTablet } from '@/hooks/media-queries/useMediaQueries';
import useSurveyBooking from '@/hooks/surveys/useSurveyBooking';
import { postRefund } from '@/network/payments';
import { deleteSurveyBooking, postSurveyBookingNotes } from '@/network/surveys';
import BookingCancellationModal from './modals/BookingCancellationModal';
import BookingNotesModal from './modals/BookingNotesModal';
import ConfirmCancellationModal from './modals/ConfirmCancellationModal';
import RescheduleSurveyModal from './modals/RescheduleSurveyModal';

function getCardTitles(status: SurveyStatus): { title: string; subtitle: string } {
  switch (status) {
    case SurveyStatus.SURVEY_COMPLETED:
      return {
        title: 'Your survey has been completed',
        subtitle: 'Our team is currently preparing your report.',
      };
    case SurveyStatus.SURVEY_CANCELLED:
      return {
        title: 'Your survey has been cancelled',
        subtitle: 'Please contact our team on 0800 058 4140 if you need anything.',
      };
    default:
      return {
        title: 'Your survey is booked',
        subtitle: 'Survey confirmation email has been sent to you.',
      };
  }
}

const getStartDateString = (date?: string, bookingSlot?: BookingSlot): string => {
  switch (bookingSlot) {
    case BookingSlot.Am:
      return `${date?.substring(0, date.indexOf('T') + 1)}${BookingSlotTimeframe.AmSlotStartHour}`;
    case BookingSlot.Pm:
      return `${date?.substring(0, date.indexOf('T') + 1)}${BookingSlotTimeframe.PmSlotStartHour}`;
    default:
      return date || '';
  }
};

const getEndDateString = (date?: string, bookingSlot?: BookingSlot): string => {
  switch (bookingSlot) {
    case BookingSlot.Am:
      return `${date?.substring(0, date.indexOf('T') + 1)}${BookingSlotTimeframe.AmSlotEndHour}`;
    case BookingSlot.Pm:
      return `${date?.substring(0, date.indexOf('T') + 1)}${BookingSlotTimeframe.PmSlotEndHour}`;
    default:
      return date || '';
  }
};

const SurveyDetailsCard = ({ jobSurvey, refetchJobSurvey }: { jobSurvey: JobSurvey; refetchJobSurvey: () => void }) => {
  const [loading, setLoading] = useState(true);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [initialNotes, setInitialNotes] = useState('');
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] = useState(false);
  const isMobile = useBelowTablet();
  const { surveyBooking, mutate: refetchSurveyBooking } = useSurveyBooking({ id: jobSurvey?.id });
  const { user } = useUser();
  const { property } = usePropertyContext();
  const cardText = getCardTitles(jobSurvey.surveyStatusID);

  const isWithin48Hours = surveyBooking
    ? differenceInHours(new Date(surveyBooking.surveyDate), new Date()) <= 48
    : false;

  const surveyCompleted = [
    SurveyStatus.SURVEY_COMPLETED,
    SurveyStatus.REPORT_IN_PROGRESS,
    SurveyStatus.REPORT_COMPLETED,
  ].includes(jobSurvey.surveyStatusID);

  useEffect(() => {
    if (jobSurvey?.id) {
      refetchSurveyBooking().finally(() => setLoading(false));
    }
  }, [jobSurvey, refetchSurveyBooking]);

  useEffect(() => {
    if (surveyBooking?.notes) {
      setInitialNotes(surveyBooking.notes);
    }
  }, [surveyBooking]);

  const getBookingSlotString = (bookingSlot?: BookingSlot): string => {
    if (bookingSlot === BookingSlot.Am) {
      return `${BookingSlotTimeframe.AmSlotStartHour.substring(0, 5)} AM - ${BookingSlotTimeframe.AmSlotEndHour.substring(0, 5)} PM`;
    } else if (bookingSlot === BookingSlot.Pm) {
      return `${BookingSlotTimeframe.PmSlotStartHour.substring(0, 5)} PM - ${BookingSlotTimeframe.PmSlotEndHour.substring(0, 5)} PM`;
    } else {
      return '-';
    }
  };

  const handleSaveNotes = async (notes: string) => {
    try {
      if (surveyBooking) {
        await postSurveyBookingNotes(surveyBooking?.id, { notes });
        await refetchSurveyBooking();
        toast.success('Booking notes updated.', { toastId: 'booking-notes-success' });
        setShowNotesModal(false);
      }
    } catch (error) {
      handleUnknownDetail(error);
    }
  };

  const handleOpenNotesModal = () => {
    setShowNotesModal(true);
    if (surveyBooking?.notes) {
      setInitialNotes(surveyBooking.notes);
    }
  };

  const handleCancelBooking = async (reason: RefundReason, comments: string) => {
    if (!surveyBooking) return;

    if (surveyBooking.goCardlessPaymentId) {
      try {
        await postRefund({
          paymentId: surveyBooking.goCardlessPaymentId,
          reference: surveyBooking.goCardlessPaymentId,
          refundReason: reason,
          refundComments: comments,
        });
      } catch (error) {
        handleUnknownDetail(error);
        return;
      }
    }

    await deleteSurveyBooking({ id: surveyBooking.id });

    toast.success('Survey booking successfully cancelled.', {
      toastId: 'survey-booking-cancelled',
    });

    setShowCancellationModal(false);
    setShowConfirmCancellationModal(true);
  };

  const getTimeRangeStringFromBookingSlot = (bookingSlot: BookingSlot): string => {
    switch (bookingSlot) {
      case BookingSlot.Am:
        return ` from ${BookingSlotTimeframe.AmSlotStartHour.substring(0, 5)} to ${BookingSlotTimeframe.AmSlotEndHour.substring(0, 5)}`;
      case BookingSlot.Pm:
        return ` from ${BookingSlotTimeframe.PmSlotStartHour.substring(0, 5)} to ${BookingSlotTimeframe.PmSlotEndHour.substring(0, 5)}`;
      default:
        return '';
    }
  };

  return (
    <>
      <div className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm relative flex size-full cursor-default flex-col gap-4 rounded-[22px] p-4 lg:px-14 lg:py-6">
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row items-center">
                <MdBookmarkAdded size={56} className="text-color-brand" />
                <div>
                  <p className="pl-4 text-[20px] font-bold">{cardText.title}</p>
                  <p className="pl-4">{cardText.subtitle}</p>
                </div>
              </div>
              <div className="bg-brand-gradient dark:bg-dark-brand-gradient mb-4 h-[0.2rem] w-full"></div>
            </div>
            <div className="flex flex-col gap-4 pl-4">
              <div className="flex flex-row items-start">
                <p className="w-[30%]">Surveyor:</p>
                <p id="surveyor-name" className="truncate font-bold">
                  {surveyBooking?.assessorName}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p className="w-[30%]">When:</p>
                <p id="survey-date" className="font-bold">
                  {surveyBooking && format(new Date(surveyBooking.surveyDate), 'EEEE, MMMM dd, yyyy')}
                  <br />
                  {getBookingSlotString(surveyBooking?.bookingSlot)}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p className="w-[30%]">Where:</p>
                <p id="survey-address" className="max-w-56 font-bold md:max-w-96">
                  {`${property?.addressLine1}${property?.addressLine2 ? `, ${property.addressLine2}` : ''}, ${property?.postcode}`}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p className="w-[30%]">Who:</p>
                <p id="user-name" className="font-bold">
                  {user?.firstName} {user?.lastName}
                  {isMobile || !user?.email ? (
                    ''
                  ) : (
                    <a className="font-normal" href={`mailto:${user.email}`}>
                      {' '}
                      (<span className="underline">{user.email}</span>)
                    </a>
                  )}
                </p>
              </div>
            </div>

            {!surveyCompleted && (
              <div className="my-6 flex w-full flex-col gap-4 sm:flex-row">
                <NewButton
                  id="notes-button"
                  variant="secondary"
                  text="Add Notes"
                  className="w-full"
                  icon={<CgMathPlus size={20} />}
                  onClick={handleOpenNotesModal}
                />
                <AddToCalendarModalButton
                  id="add-survey-to-calendar"
                  name={`Energy Assessment Survey - ${property?.addressLine1}`}
                  location={
                    property?.addressLine1 && property?.postcode
                      ? `${property.addressLine1}, ${property.postcode}`
                      : undefined
                  }
                  startDate={getStartDateString(surveyBooking?.surveyDate.toString(), surveyBooking?.bookingSlot)}
                  endDate={getEndDateString(surveyBooking?.surveyDate.toString(), surveyBooking?.bookingSlot)}
                  description={`An energy assessment survey will be conducted${surveyBooking?.assessorName ? ` by ${surveyBooking.assessorName}` : ''} at ${property?.addressLine1 || 'your property'} on ${surveyBooking?.surveyDate ? format(new Date(surveyBooking.surveyDate.toString()), 'dd/MM/yyyy') : ''}${surveyBooking?.bookingSlot ? getTimeRangeStringFromBookingSlot(surveyBooking?.bookingSlot) : ''}.[br][br]Although the survey itself will take only 1-2 hours, please ensure you are available for the entire four-hour window.[br][br]Thank you for trusting Epc Builder!`}
                  containerClassName="w-full"
                />
              </div>
            )}
            {!surveyCompleted && (
              <>
                {isWithin48Hours ? (
                  <p>
                    If you need to rearrange or cancel, contact{' '}
                    <a href="mailto:support@epcbuilder.com" className="font-bold underline">
                      support@epcbuilder.com
                    </a>
                  </p>
                ) : (
                  <p>
                    Want to make a change?{' '}
                    <button className="cursor-pointer font-bold underline" onClick={() => setShowRescheduleModal(true)}>
                      Reschedule
                    </button>{' '}
                    or{' '}
                    <button
                      id="cancel-booking-button"
                      onClick={() => setShowCancellationModal(true)}
                      className="cursor-pointer font-bold underline"
                    >
                      Cancel the assessment
                    </button>
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {showNotesModal && (
        <BookingNotesModal
          onClose={() => setShowNotesModal(false)}
          initialNotes={initialNotes}
          onSave={handleSaveNotes}
        />
      )}
      {showRescheduleModal && (
        <RescheduleSurveyModal
          surveyBooking={surveyBooking}
          refetchJobSurvey={refetchSurveyBooking}
          onClose={() => setShowRescheduleModal(false)}
        />
      )}
      {showCancellationModal && surveyBooking && (
        <BookingCancellationModal
          message="You are about to cancel the booking on "
          messageLine2="A full refund will be processed and issued to your bank account within 7 days."
          value={surveyBooking}
          onClose={() => setShowCancellationModal(false)}
          handleCancelBooking={handleCancelBooking}
          handleRescheduleBooking={() => {
            setShowRescheduleModal(true);
          }}
        />
      )}
      {showConfirmCancellationModal && (
        <ConfirmCancellationModal
          value={surveyBooking}
          onClose={() => {
            setShowCancellationModal(false), refetchJobSurvey();
          }}
        />
      )}
    </>
  );
};

export default SurveyDetailsCard;
